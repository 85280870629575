.app-header {
  z-index: 1; }
  .app-header .header-tool {
    padding: 0 3%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 30px;
    background: #FBF8F8; }
  .app-header .divider-vertical {
    margin: 0 10px; }
  .app-header .header-tool-right a:not(:first-child) {
    margin-left: 35px; }
  .app-header .header-tool-right a:hover {
    color: #888; }
  .app-header .header-tool-right .online-demo-btn {
    display: inline-block;
    padding: 0 12px;
    color: #fff;
    background: linear-gradient(90deg, #53A0FD 20%, #3023AE 100%); }
    .app-header .header-tool-right .online-demo-btn:hover {
      color: #cccccc; }
  .app-header .header-content {
    top: 30px;
    padding: 0 3%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);
    overflow: hidden; }
  .app-header .navMenu {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
  .app-header .logo,
  .app-header .navItem {
    cursor: pointer;
    position: relative; }
    .app-header .logo.active:after,
    .app-header .navItem.active:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      height: 4px;
      width: 100%;
      background: red;
      background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%); }
    .app-header .logo:hover .navItem-title,
    .app-header .logo:hover .navItem-desc,
    .app-header .navItem:hover .navItem-title,
    .app-header .navItem:hover .navItem-desc {
      color: #888; }
  .app-header .logo {
    width: 145px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: cover; }
  .app-header .navItem:not(:last-child) {
    margin-right: 4%; }
  .app-header .navItem-title {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%; }
  .app-header .navItem-desc {
    font-size: 12px;
    line-height: 17px;
    color: black;
    font-weight: 100;
    margin-top: 9px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%; }
  @media only screen and (max-width: 1200px) {
    .app-header .navItem-desc {
      display: none; } }

.app-footer {
  background: #fff;
  padding: 34px 7% 18px 7%;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 50px; }
  .app-footer .logo {
    margin-bottom: 10px;
    background-size: cover;
    height: 40px;
    width: 145px; }
  .app-footer .copyright {
    font-size: 12px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    margin-top: 23px; }
  .app-footer .left-item {
    margin-top: 15px; }
  .app-footer .right-content {
    display: flex;
    justify-content: center; }
  @media only screen and (max-width: 1000px) {
    .app-footer .right-content {
      justify-content: flex-start;
      margin-top: 20px; } }
  .app-footer .right-item:not(:first-child) {
    margin-left: 56px; }
  .app-footer .right-item-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #000;
    margin: 5px 0; }
  .app-footer .right-subitem {
    display: inline-block; }
    .app-footer .right-subitem:not(:first-child) {
      margin-left: 56px; }
  .app-footer .right-item-item {
    line-height: 30px;
    font-weight: 300;
    margin-top: 10px; }

.contactUs {
  padding: 60px 7%;
  margin-top: 50px;
  background: #fff; }
  .contactUs .normal-section-title.title,
  .contactUs .normal-section-desc.desc {
    text-align: left; }
  .contactUs .normal-section-desc.desc {
    width: 100%;
    line-height: 30px; }
  .contactUs .section-second-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000;
    margin-top: 24px; }
  .contactUs .right {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400; }
    .contactUs .right span:not(:first-child) {
      margin-left: 20px; }

.mainSection {
  position: relative; }
  .mainSection img {
    width: 100%;
    position: absolute;
    bottom: 0; }
  .mainSection .img-wrapper {
    height: 500px;
    overflow: hidden;
    position: relative; }
  .mainSection .mainSectionContent {
    text-align: center;
    display: flex;
    align-items: center; }
    .mainSection .mainSectionContent > div {
      width: 100%; }
    .mainSection .mainSectionContent.hasImg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      color: #fff; }
      .mainSection .mainSectionContent.hasImg .section-title {
        text-shadow: 5px 5px 5px #026dc5; }
      .mainSection .mainSectionContent.hasImg .section-desc {
        text-shadow: 5px 5px 5px #026dc5; }

.wrapper-button {
  height: 54px;
  font-size: 18px;
  font-weight: 400; }
  .wrapper-button.hover-move-top:hover {
    transform: translate3d(0, -15px, 0); }
  .wrapper-button.ant-btn.ant-btn-background-ghost.ant-btn:focus {
    color: #fff;
    border-color: #fff; }
  .wrapper-button.ant-btn.ant-btn-background-ghost:hover {
    background: #fff !important;
    color: #3023AE !important;
    border-color: #fff; }
  .wrapper-button.whiteBtn.ant-btn span {
    background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent; }
  .wrapper-button.whiteBtn.ant-btn:focus, .wrapper-button.whiteBtn.ant-btn:hover {
    border-color: #fff; }
  .wrapper-button.linearBtn {
    background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%);
    color: #fff; }
    .wrapper-button.linearBtn:hover {
      border-color: #fff;
      background: #fff;
      color: #3023AE; }

.linearBorderBtn {
  display: inline-block;
  background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%);
  padding: 1px;
  border-radius: 4px; }
  .linearBorderBtn .wrapper-button.ant-btn {
    border-radius: 3px;
    border: none; }
  .linearBorderBtn:hover .wrapper-button.ant-btn {
    background: transparent;
    color: #fff; }

.imgCard {
  overflow: hidden;
  height: 100%; }
  .imgCard img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s; }
  .imgCard .cardWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.5s; }
  .imgCard:hover .cardWrapper {
    transform: translate3d(0, -30px, 0); }
  .imgCard:hover img {
    transform: scale3d(1.1, 1.1, 1) translate3d(0, -20px, 0); }
  .imgCard .content-wrapper {
    flex: 1;
    padding: 30px 36px 33px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .imgCard .title {
    font-size: 30px;
    font-weight: 300;
    line-height: 42px;
    letter-spacing: 2px;
    color: #000; }
    .imgCard .title .tip {
      font-size: 14px;
      line-height: 32px;
      margin-left: 5px;
      background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent; }
  .imgCard .content {
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 2px;
    color: #000;
    margin: 20px 0; }
  .imgCard.horizontal .cardWrapper {
    flex-direction: row; }
  .imgCard.horizontal .img-wrapper {
    width: 50%; }
  .imgCard.horizontal .content-wrapper {
    padding: 0 58px;
    justify-content: center; }
  .imgCard.horizontal:hover .cardWrapper {
    transform: translate3d(0, -10px, 0); }
  .imgCard.horizontal:hover img {
    transform: scale3d(1.05, 1.05, 1); }

.blockCard {
  overflow: hidden;
  height: 100%;
  transition: box-shadow 0.5s; }
  .blockCard img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s; }
  .blockCard .blockCard-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.5s; }
  .blockCard .block-content {
    flex: 1;
    padding: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .blockCard .link {
    font-size: 16px;
    background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 10px; }
  .blockCard .date-text,
  .blockCard .intro-text {
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 40px;
    color: #9C9C9C;
    font-weight: 500; }
  .blockCard .title {
    font-size: 25px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 2px;
    color: #000;
    margin-bottom: 10px; }
    .blockCard .title.second-title {
      font-size: 20px;
      line-height: 40px; }
  .blockCard.shadow {
    box-shadow: 0 8px 10px rgba(10, 16, 20, 0.1), 0 0 10px rgba(10, 16, 20, 0.08); }
  .blockCard:hover {
    box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12); }
    .blockCard:hover .blockCard-container {
      transform: translate3d(0, -10px, 0); }
    .blockCard:hover img {
      transform: scale3d(1.1, 1.1, 1); }

.homeView .home-section1-section2-wrapper {
  overflow: hidden; }

.homeView .home-section2 {
  padding: 45px; }

.homeView .home-section2-item {
  background: #fff;
  height: 100%; }

.homeView .home-section3 {
  padding: 64px 24px;
  background: #fff; }

.productView .product-section1 .bg-img {
  height: 500px;
  width: 100%; }

.productView .product-section1-header {
  font-weight: 400;
  color: #000;
  border-bottom: 1px solid #C1C1C1;
  height: 60px;
  background: #fff; }
  .productView .product-section1-header .title {
    margin-left: 20%; }
  .productView .product-section1-header .func-tab {
    text-align: center; }
    .productView .product-section1-header .func-tab span {
      cursor: pointer;
      margin-left: 30px; }
      .productView .product-section1-header .func-tab span.active {
        background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent; }

.productView .card-container {
  padding: 0 45px; }

.productView .card-item {
  background: #fff;
  height: 100%; }

.productView .product-section1-intro {
  font-size: 18px;
  color: #000;
  font-weight: 300;
  line-height: 60px;
  text-align: center; }

.productView .product-section2 .item-title {
  font-size: 45px;
  font-weight: 300;
  line-height: 63px;
  text-align: center; }
  .productView .product-section2 .item-title span {
    background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent; }

.productView .product-section2 .item-content {
  text-align: center;
  font-weight: 300;
  line-height: 30px;
  color: #0a0a0a;
  text-align: center;
  font-size: 16px;
  padding: 55px 32px 0 32px; }

.productView .product-section3 {
  background: #fff;
  margin-top: 45px;
  padding: 50px 0 26px 0; }
  .productView .product-section3 .normal-section-title {
    padding-bottom: 10px; }
  .productView .product-section3 .tab-img-wrapper {
    padding: 0 16px 16px 16px; }
  .productView .product-section3 .tab-img {
    width: 100%;
    box-shadow: 0 8px 8px rgba(10, 16, 20, 0.35), 0 0 8px rgba(10, 16, 20, 0.35); }

.productView .product-section4 {
  background: #fff;
  margin-top: 45px;
  padding-bottom: 45px; }
  .productView .product-section4 .props-wrapper {
    width: 70%;
    margin: auto; }
  .productView .product-section4 .props-item {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    border-bottom: 1px solid #ccc; }
    .productView .product-section4 .props-item:first-child {
      border-top: 1px solid #ccc; }
  .productView .product-section4 .props-item-title {
    width: 250px;
    text-align: right;
    font-size: 20px;
    color: #000;
    line-height: 30px;
    padding-left: 8px; }
  .productView .product-section4 .props-item-content {
    flex: 1;
    padding: 0 8px 0 80px;
    font-size: 18px;
    color: #4a4a4a;
    line-height: 28px;
    font-weight: 300; }

.productView .product-itsm-section2 {
  overflow-x: hidden; }
  .productView .product-itsm-section2 .f-block-wrapper {
    padding: 0 80px; }
  .productView .product-itsm-section2 .f-item {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 100%;
    font-weight: 300;
    transition: box-shadow 0.5s ease-in-out; }
    .productView .product-itsm-section2 .f-item:hover {
      box-shadow: 0 8px 10px rgba(10, 16, 20, 0.22), 0 0 10px rgba(10, 16, 20, 0.12); }
  .productView .product-itsm-section2 .f-item-container {
    flex: 1; }
  .productView .product-itsm-section2 .f-item-title {
    font-size: 25px;
    line-height: 36px;
    letter-spacing: 2px;
    color: #000; }
  .productView .product-itsm-section2 .f-item-content {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 2px;
    color: #333;
    margin: 15px 0; }
  .productView .product-itsm-section2 .f-item-link a {
    background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    line-height: 25px;
    color: #000; }

.productView .product-itsm-section3 {
  margin: auto;
  background: #fff;
  margin-top: 45px; }
  .productView .product-itsm-section3 .p-itsm-card-desc {
    font-size: 18px; }
  .productView .product-itsm-section3 .p-itsm-card-content {
    margin: 50px 0; }

.productView .product-itsm-section4 {
  overflow-x: hidden; }
  .productView .product-itsm-section4 .se-block-wrapper {
    padding: 0 80px; }
  .productView .product-itsm-section4 .se-item {
    background: #fff;
    padding: 24px;
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 300;
    color: #000;
    line-height: 36px;
    letter-spacing: 2px; }
    .productView .product-itsm-section4 .se-item .anticon {
      margin-right: 30px; }

.productView.productItsm .product-itsm-section1 {
  display: flex;
  background: #fff;
  font-weight: 300;
  color: #000;
  overflow: hidden; }
  .productView.productItsm .product-itsm-section1 .product-itsm-section1-img-wrapper {
    flex: 1;
    background-size: cover;
    transition: transform 0.5s ease-in-out; }
  .productView.productItsm .product-itsm-section1 .product-itsm-section1-content-wrapper {
    width: 50%;
    padding: 35px; }
  .productView.productItsm .product-itsm-section1 .content-content,
  .productView.productItsm .product-itsm-section1 .content-header {
    padding: 20px 0 0 20px; }
  .productView.productItsm .product-itsm-section1 .content-header {
    position: relative; }
    .productView.productItsm .product-itsm-section1 .content-header::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 15px;
      left: 0;
      width: 4px;
      background: linear-gradient(180deg, #3023AE 20%, #53A0FD 90%); }
  .productView.productItsm .product-itsm-section1 .content-header-title {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 30px; }
  .productView.productItsm .product-itsm-section1 .content-header-second-title {
    font-size: 25px;
    line-height: 60px; }
  .productView.productItsm .product-itsm-section1 .content-content {
    font-size: 18px;
    line-height: 36px; }
  .productView.productItsm .product-itsm-section1 .content-btns {
    margin-left: 20px; }
    .productView.productItsm .product-itsm-section1 .content-btns .linearBorderBtn {
      margin-top: 25px; }
    .productView.productItsm .product-itsm-section1 .content-btns .linearBorderBtn:not(:last-child) {
      margin-right: 30px; }
  .productView.productItsm .product-itsm-section1:hover .product-itsm-section1-img-wrapper {
    transform: scale3d(1.02, 1.02, 1); }

.productView.productItsm .product-itsm-section3-f {
  background: #fff;
  padding-bottom: 45px; }
  .productView.productItsm .product-itsm-section3-f .carousel-item img {
    width: 80%;
    height: 600px;
    margin: 0 auto 10px auto; }

.productView .tab-pane-wrapper {
  transition: all 0.5s ease-in-out;
  opacity: 0; }
  .productView .tab-pane-wrapper.active {
    opacity: 1; }

.customerCaseView .customerCase-section2 {
  margin-top: 45px;
  background: #fff;
  padding: 50px 0 26px 0; }
  .customerCaseView .customerCase-section2 .normal-section-title {
    padding-bottom: 10px; }

.aboutView .card-container {
  padding: 45px; }

.aboutView .card-item {
  background: #fff;
  height: 100%; }

.aboutView .about-section2 .imgCard .title {
  background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent; }

.aboutView .about-section3,
.aboutView .about-section4 {
  background: #fff;
  margin-top: 45px; }

.aboutView .about-section3-img-wrapper {
  text-align: center;
  padding: 15px 0 45px 0; }
  .aboutView .about-section3-img-wrapper img {
    height: 550px; }

.aboutView .filter-list-wrapper {
  min-height: 500px; }

.newsSubView .news-content {
  background: #fff;
  color: #0a0a0a;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px; }

.newsSubView .news-sub-intro,
.newsSubView .news-sub-date {
  padding: 15px 0 25px 0; }

.newsSubView .news-sub-title {
  font-size: 40px;
  line-height: 56px; }

.newsSubView .pv-btn-wrapper {
  line-height: 56px;
  font-size: 16px;
  display: flex;
  justify-content: space-between; }
  .newsSubView .pv-btn-wrapper div:not(:first-child) {
    text-align: right; }
  .newsSubView .pv-btn-wrapper .btn-next,
  .newsSubView .pv-btn-wrapper .btn-prev {
    font-size: 16px;
    padding: 0; }
    .newsSubView .pv-btn-wrapper .btn-next .anticon,
    .newsSubView .pv-btn-wrapper .btn-prev .anticon {
      transition: transform 0.5s ease-in-out; }
    .newsSubView .pv-btn-wrapper .btn-next:not([disabled]):hover,
    .newsSubView .pv-btn-wrapper .btn-prev:not([disabled]):hover {
      color: #1890ff; }
  .newsSubView .pv-btn-wrapper .btn-prev:not([disabled]):hover .anticon {
    transform: translate3d(-5px, 0, 0); }
  .newsSubView .pv-btn-wrapper .btn-next:not([disabled]):hover .anticon {
    transform: translate3d(5px, 0, 0); }
  .newsSubView .pv-btn-wrapper .pn-desc {
    font-size: 14px;
    line-height: 22px;
    color: #666;
    margin-bottom: 24px; }

.onlineExperienceView .main-desc {
  background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  font-weight: 500;
  cursor: pointer; }
  .onlineExperienceView .main-desc:after {
    content: '';
    height: 2px;
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%); }

.solutionsSubView .solutions-sub-section2 {
  background: #fff;
  margin-top: 45px; }
  .solutionsSubView .solutions-sub-section2.normal-media-padding {
    padding-top: 15px !important;
    padding-bottom: 36px !important; }

.solutionsSubView .solutions-sub-title {
  padding-top: 10px;
  font-size: 25px;
  line-height: 36px;
  letter-spacing: 2px;
  color: #000;
  margin: 20px 0; }

.solutionsSubView .solutions-sub-content {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: #0a0a0a;
  margin-bottom: 30px; }

.solutionsSubView .solutions-sub-section3-block {
  height: 100%;
  background: #fff;
  padding: 25px 17px; }

.solutionsSubView .solutions-sub-section3-block-title {
  font-size: 25px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 2px;
  color: #000;
  margin-bottom: 15px; }

.solutionsSubView .solutions-sub-section3-block-content {
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 2px;
  color: #333; }

@font-face {
  font-family: PingFang;
  src: url("./fonts/PingFang SC Thin.otf"); }

body {
  font-family: PingFang, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
  font-size: 14px; }

html, body, ul, li {
  margin: 0;
  padding: 0; }

.app-container {
  color: #333333;
  background: #EBEEF0; }
  .app-container a {
    color: #333333; }
  .app-container .normal-media-width {
    width: 80%;
    margin: 0 auto; }
  .app-container .normal-media-padding {
    padding: 0 10%; }
  .app-container .normal-section-title {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 2px;
    color: #000;
    font-weight: 400;
    text-align: center; }
  .app-container .normal-section-desc {
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
    color: #000;
    text-align: center;
    width: 80%;
    margin: 24px auto; }
  .app-container .section-title {
    font-size: 40px;
    font-weight: 300;
    line-height: 70px; }
  .app-container .section-desc {
    font-size: 25px;
    line-height: 60px;
    margin: 51px auto 34px;
    width: 70%;
    font-weight: 300; }
  .app-container .light-content {
    background-color: #f7fafd; }
  .app-container .filter-bar {
    height: 89px;
    padding: 0 89px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .app-container .filter-bar .title {
      display: inline-block;
      font-size: 20px;
      color: #4b4b4b;
      letter-spacing: 2px;
      width: 200px;
      text-align: center; }
    .app-container .filter-bar .ant-btn {
      height: 52px;
      margin-left: 28px;
      font-size: 20px;
      letter-spacing: 2px;
      border-color: #fff;
      padding: 0 20px 0 38px;
      border-radius: 8px;
      color: #4b4b4b; }
      .app-container .filter-bar .ant-btn .anticon {
        margin-left: 20px; }
  .app-container .filter-list-wrapper .filter-result {
    font-size: 15px;
    letter-spacing: 2px;
    color: #4b4b4b;
    padding: 20px 142px 10px 142px; }
  .app-container .filter-list-wrapper .filter-list {
    padding: 0 80px; }
  .app-container .verticalTab-wrapper {
    background: #fff; }
    .app-container .verticalTab-wrapper .ant-tabs {
      color: #0a0a0a;
      font-weight: 400; }
      .app-container .verticalTab-wrapper .ant-tabs .ant-tabs-left-bar {
        border-right: 1px solid #d1d1d1;
        margin-right: -1px; }
        .app-container .verticalTab-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
          text-align: left;
          padding: 4px 64px;
          font-size: 16px; }
        .app-container .verticalTab-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
          background-color: transparent; }
        .app-container .verticalTab-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-nav {
          margin: 10px 0; }
      .app-container .verticalTab-wrapper .ant-tabs .ant-tabs-left-content {
        padding-left: 0px;
        border-left: 1px solid #d1d1d1; }
    .app-container .verticalTab-wrapper .ant-tabs-nav .ant-tabs-tab-active {
      font-weight: 500; }
      .app-container .verticalTab-wrapper .ant-tabs-nav .ant-tabs-tab-active .tab-title {
        background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent; }
      .app-container .verticalTab-wrapper .ant-tabs-nav .ant-tabs-tab-active:before {
        content: '';
        background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%);
        width: 30px;
        height: 8px;
        position: absolute;
        left: 0;
        top: calc(50% - 4px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
    .app-container .verticalTab-wrapper .ant-tabs-tab-prev {
      color: #1890ff; }
      .app-container .verticalTab-wrapper .ant-tabs-tab-prev:hover {
        color: #0358a8; }
    .app-container .verticalTab-wrapper .content-title {
      font-size: 30px;
      line-height: 65px;
      padding-left: 43px;
      border-bottom: 1px solid #d1d1d1;
      font-weight: 500; }
      .app-container .verticalTab-wrapper .content-title span {
        background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent; }
    .app-container .verticalTab-wrapper .content-container {
      padding: 24px 43px;
      overflow: auto; }
      .app-container .verticalTab-wrapper .content-container a {
        color: #1890ff;
        line-height: 32px; }
  .app-container .faqView .faq-search {
    font-weight: 300;
    color: #999999;
    height: 60px; }
    .app-container .faqView .faq-search .ant-input {
      font-size: 18px;
      padding: 0 30px; }
    .app-container .faqView .faq-search .anticon {
      margin-right: 10px; }
  .app-container .faqView .bottom-extra {
    text-align: center;
    margin-top: 45px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
    color: #000; }
  .app-container .linearText {
    background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent; }
  .app-container .downloadView .download-section1 {
    background: #fff; }
  .app-container .downloadView .download-form-img {
    background-position: center;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
  .app-container .downloadView .download-img-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4); }
  .app-container .downloadView .download-form-container {
    color: #fff;
    max-width: 80%;
    z-index: 1; }
    .app-container .downloadView .download-form-container .anticon {
      font-size: 29px;
      line-height: 90px; }
  .app-container .downloadView .download-img-title {
    font-size: 25px;
    font-weight: 500;
    line-height: 60px; }
  .app-container .downloadView .download-img-desc-item {
    font-size: 16px;
    line-height: 30px; }
  .app-container .downloadView .download-img-link {
    text-decoration: underline;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
    margin-top: 45px; }
  .app-container .downloadView .download-form-title {
    font-size: 25px;
    line-height: 60px;
    color: #0a0a0a;
    padding: 0 10%;
    margin-bottom: 25px;
    text-align: center; }
  .app-container .downloadView .form-wrapper {
    height: 100%;
    padding: 45px 25px 21px 4px; }
  .app-container .downloadView .download-section2 {
    background: #fff;
    margin-top: 45px;
    margin-bottom: 45px;
    padding-bottom: 45px; }
  .app-container .downloadView .ant-table {
    font-size: 16px;
    color: #4a4a4a;
    font-weight: 300; }
  .app-container .downloadView .ant-table-thead > tr > th {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #1890ff;
    background: transparent;
    font-size: 18px;
    font-weight: 400; }
  .app-container .news-other-content {
    margin-top: 45px; }
  .app-container .markdown-body a {
    color: #1890ff;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    font-weight: 900; }
    .app-container .markdown-body a:hover {
      color: #50a9fd; }
  .app-container .markdown-body img {
    margin: auto;
    display: block;
    max-width: 95%; }
  .app-container .markdown-body table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.05rem;
    font-size: 14px;
    line-height: 25px;
    border-collapse: collapse;
    width: 100%; }
    .app-container .markdown-body table thead {
      text-align: left; }
    .app-container .markdown-body table td:first-child, .app-container .markdown-body table th:first-child {
      padding-left: .93333rem; }
    .app-container .markdown-body table td, .app-container .markdown-body table th {
      color: rgba(0, 0, 0, 0.85);
      text-align: left !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-feature-settings: "tnum";
      padding-left: 0.93333rem;
      padding-right: 0.93333rem;
      padding-top: 0.7rem;
      padding-bottom: calc(0.7rem - 1px); }
    .app-container .markdown-body table th {
      font-weight: bold; }
    .app-container .markdown-body table td a {
      color: #1890ff;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color .3s; }
  .app-container .markdown-body h1, .app-container .markdown-body h2, .app-container .markdown-body h3, .app-container .markdown-body h4, .app-container .markdown-body h5, .app-container .markdown-body h6 {
    font-weight: 700;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.38316rem; }
  .app-container .hidden-expand-row .ant-table-row-expand-icon-cell {
    visibility: hidden; }
  .app-container .ant-table {
    font-size: 16px; }
  .app-container .table-text-bold,
  .app-container .ant-table-thead > tr > th:not(.table-text-config) {
    font-weight: 900;
    color: rgba(0, 0, 0, 0.85); }
  .app-container .table-text-config {
    background: #1890ff;
    color: #fff; }
  .app-container .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .app-container .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .app-container .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .app-container .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    color: rgba(0, 0, 0, 0.85); }

.carousel-wrapper .ant-carousel .slick-slide {
  overflow: hidden; }

.carousel-wrapper .ant-carousel .slick-dots li {
  margin: 0 10px; }
  .carousel-wrapper .ant-carousel .slick-dots li button {
    background: #8d8989;
    width: 36px;
    height: 4px; }
  .carousel-wrapper .ant-carousel .slick-dots li.slick-active button {
    background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%); }

.carousel-wrapper .carousel-item {
  padding: 24px 0; }

.breadcrumb-wrapper {
  padding: 0 3%; }

.custom-tab-center .ant-tabs-bar {
  position: relative;
  border: none; }
  .custom-tab-center .ant-tabs-bar:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: #c1c1c1; }

.custom-tab-center.tab-left {
  font-weight: 500;
  color: #0a0a0a;
  padding-bottom: 16px; }
  .custom-tab-center.tab-left .ant-tabs-bar {
    margin: 0; }

.custom-tab-center .ant-tabs-nav-wrap {
  margin-bottom: 0; }

.custom-tab-center .ant-tabs-nav {
  font-size: 16px;
  line-height: 40px; }

.custom-tab-center .ant-tabs-nav .ant-tabs-tab-active {
  background-image: linear-gradient(45deg, #3023AE 20%, #53A0FD 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: normal; }

.custom-tab-center .ant-tabs-ink-bar {
  background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%); }

.normal-form input.ant-input {
  height: 44px; }

.normal-form .ant-form-item-label {
  line-height: 44px;
  padding-right: 30px; }
  .normal-form .ant-form-item-label label {
    font-size: 18px;
    font-weight: 300; }

.normal-form .ant-select-selection--single {
  height: 44px; }
  .normal-form .ant-select-selection--single > div {
    line-height: 44px; }

.normal-form .ant-btn {
  background: linear-gradient(90deg, #3023AE 20%, #53A0FD 100%);
  height: 60px;
  padding: 0 35px;
  border: none;
  font-size: 18px;
  font-weight: 500; }
