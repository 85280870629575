.contactUs {
    padding: 60px 7%;
    margin-top: 50px;
    background: #fff;
    .normal-section-title.title,
    .normal-section-desc.desc {
        text-align: left;
    }
    .normal-section-desc.desc {
        width: 100%;
        line-height: 30px;
    }
    .section-second-title {
        font-weight: $basic-font-weight-regular;
        font-size: 20px;
        line-height: 28px;
        color: #000;
        margin-top: 24px;
    }
    .right {
        font-size: 18px;
        line-height: 25px;
        font-weight: $basic-font-weight-regular;
        span:not(:first-child) {
            margin-left: 20px;
        }
    }
}