.blockCard {
    overflow: hidden;
    height: 100%;
    transition: box-shadow 0.5s;
    img {
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
    }
    .blockCard-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: transform 0.5s;
    }
    .block-content {
        flex: 1;
        padding: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .link {
        font-size: 16px;
        @include linearText();margin-top: 10px;
    }
    .date-text,
    .intro-text {
        font-size: 15px;
        letter-spacing: 2px;
        line-height: 40px;
        color: #9C9C9C;
        font-weight: $basic-font-weight-medium;
    }
    .title {
        font-size: 25px;
        font-weight: $basic-font-weight-light;
        line-height: 36px;
        letter-spacing: 2px;
        color: #000;
        margin-bottom: 10px;
        &.second-title {
            font-size: 20px;
            line-height: 40px;
        }
    }
    &.shadow {
        box-shadow: 0 8px 10px rgba(10, 16, 20, .1), 0 0 10px rgba(10, 16, 20, .08);
    }
    &:hover {
        .blockCard-container {
            transform: translate3d(0, -10px, 0);
        }
        img {
            transform: scale3d(1.1, 1.1, 1);
        }
        box-shadow: 0 8px 8px rgba(10, 16, 20, .24), 0 0 8px rgba(10, 16, 20, .12);
    }
}