.app-header {
    z-index: 1;
    .header-tool {
        padding: 0 3%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 30px;
        background: #FBF8F8;
    }
    .divider-vertical {
        margin: 0 10px;
    }
    .header-tool-right {
        a:not(:first-child) {
            margin-left: 35px;
        }
        a:hover {
            color: #888;
        }
        .online-demo-btn {
            display: inline-block;
            padding: 0 12px;
            color: #fff;
            @include linearbackground(true);
            &:hover {
                // background: #fff;
                color:#cccccc;
            }
        }
    }
    .header-content {
        top: 30px;
        padding: 0 3%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.9);
        overflow: hidden;
    }
    .navMenu {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
    .logo,
    .navItem {
        cursor: pointer;
        position: relative;
        &.active:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -15px;
            height: 4px;
            width: 100%;
            background: red;
            @include linearbackground;
        }
        &:hover {
            .navItem-title,
            .navItem-desc {
                color: #888;
            }
        }
    }
    .logo {
        width: 145px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .navItem:not(:last-child) {
        margin-right: 4%;
    }
    .navItem-title {
        font-size: 16px;
        line-height: 22px;
        color: #000;
        // font-weight: 400;
        font-weight: $basic-font-weight-regular;
        @include overtext;
    }
    .navItem-desc {
        font-size: 12px;
        line-height: 17px;
        color: rgba(0, 0, 0, 1);
        font-weight: $basic-font-weight-thin;
        margin-top: 9px;
        @include overtext;
    }
    @media only screen and (max-width: 1200px) {
        .navItem-desc {
            display: none;
        }
    }
}

