@mixin overtext($width:100%) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: $width;
}

//字体渐变色
@mixin linearText($start: #3023AE, $end: #53A0FD) {
    background-image: linear-gradient(45deg, $start 20%, $end 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

// 背景渐变色  @param isRTL是否从右到左 默认false
@mixin linearbackground($isRTL:false, $start:#3023AE, $end:#53A0FD, $deg:90deg) {
    @if $isRTL {
        background: linear-gradient($deg, $end 20%, $start 100%);
    }@else {
        background: linear-gradient($deg, $start 20%, $end 100%);
    }
}