.productView {
    .product-section1 {
        .bg-img {
           height: 500px;
           width: 100%; 
        }
    }
    .product-section1-header {
        font-weight: $basic-font-weight-regular;
        color: #000;
        border-bottom: 1px solid #C1C1C1;
        height: 60px;
        background: #fff;
        .title {
            margin-left: 20%;
        }
        .func-tab {
            text-align: center;
            span {
                cursor: pointer;
                margin-left: 30px;
                &.active {
                    @include linearText();
                }
            }
        }
    }
    .card-container {
        padding: 0 45px;
    }
    .card-item {
        background: #fff;
        height: 100%;
    }
    .product-section1-intro {
        font-size: 18px;
        color: #000;
        font-weight: $basic-font-weight-light;
        line-height: 60px;
        text-align: center;
    }
    .product-section2 {
        .item-title {
            font-size: 45px;
            font-weight: $basic-font-weight-light;
            line-height: 63px;
            text-align: center;
            span{
                @include linearText();
            }
        }
        .item-content {
            text-align: center;
            font-weight: $basic-font-weight-light;
            line-height: 30px;
            color: #0a0a0a;
            text-align: center;
            font-size: 16px;
            padding: 55px 32px 0 32px;
        }
    }
    .product-section3 {
        background: #fff;
        margin-top: 45px;
        padding: 50px 0 26px 0;
        .normal-section-title {
            padding-bottom: 10px;
        }
        .tab-img-wrapper {
            padding: 0 16px 16px 16px;
        }
        .tab-img {
            width: 100%;
            box-shadow: 0 8px 8px rgba(10, 16, 20, .35), 0 0 8px rgba(10, 16, 20, .35);
        }
    }
    .product-section4 {
        background: #fff;
        margin-top: 45px;
        padding-bottom: 45px;
        .props-wrapper {
            width: 70%;
            margin: auto;
        }
        .props-item {
            display: flex;
            align-items: center;
            padding: 20px 0px;
            border-bottom: 1px solid #ccc;
            &:first-child {
                border-top: 1px solid #ccc;
            }
        }
        .props-item-title {
            width: 250px;
            text-align: right;
            font-size: 20px;
            color: #000;
            line-height: 30px;
            padding-left: 8px;
        }
        .props-item-content {
            flex: 1;
            padding: 0 8px 0 80px;
            font-size: 18px;
            color: #4a4a4a;
            line-height: 28px;
            font-weight: $basic-font-weight-light;
        }
    }
    .product-itsm-section2 {
        overflow-x: hidden;
        .f-block-wrapper {
            padding: 0 80px;
        }
        .f-item {
            background: #fff;
            display: flex;
            flex-direction: column;
            padding: 16px;
            height: 100%;
            font-weight: $basic-font-weight-light;
            transition: box-shadow 0.5s ease-in-out;
            &:hover {
                box-shadow: 0 8px 10px rgba(10, 16, 20, .22), 0 0 10px rgba(10, 16, 20, .12);
            }
        }
        .f-item-container {
            flex: 1;
        }
        .f-item-title {
            font-size: 25px;
            line-height: 36px;
            letter-spacing: 2px;
            color: #000;
        }
        .f-item-content {
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 2px;
            color: #333;
            margin: 15px 0;
        }
        .f-item-link a{
            @include linearText();
            font-size: 18px;
            line-height: 25px;
            color: #000;
        }
    }
    .product-itsm-section3 {
        margin: auto;
        background: #fff;
        margin-top: 45px;
        .p-itsm-card-desc {
            font-size: 18px;
        }
        .p-itsm-card-content {
            margin: 50px 0;
        }
    }
    .product-itsm-section4 {
        overflow-x: hidden;
        .se-block-wrapper {
            padding: 0 80px;
        }
        .se-item {
            background: #fff;
            padding: 24px;
            display: flex;
            align-items: center;
            font-size: 25px;
            font-weight: $basic-font-weight-light;
            color: #000;
            line-height: 36px;
            letter-spacing: 2px;
            .anticon {
                margin-right: 30px;
            }
        }
    }
    &.productItsm {
        .product-itsm-section1 {
            display: flex;
            background: #fff;
            font-weight: $basic-font-weight-light;
            color: #000;
            overflow: hidden;
            .product-itsm-section1-img-wrapper {
                flex: 1;
                background-size: cover;
                transition: transform 0.5s ease-in-out;
                // overflow: hidden;
                // img {
                //     width: 100%;
                //     height: 100%;
                //     transition: transform 0.5s ease-in-out;
                // }
            }
            .product-itsm-section1-content-wrapper {
                width: 50%;
                padding: 35px;
            }
            .content-content,
            .content-header {
                padding: 20px 0 0 20px;
            }
            .content-header {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 15px;
                    left: 0;
                    width: 4px;
                    background: linear-gradient(180deg, #3023AE 20%, #53A0FD 90%);
                }
            }
            .content-header-title {
                font-size: 40px;
                line-height: 56px;
                margin-bottom: 30px;
            }
            .content-header-second-title {
                font-size: 25px;
                line-height: 60px;
            }
            .content-content {
                font-size: 18px;
                line-height: 36px;
            }
            .content-btns {
                margin-left: 20px;
                .linearBorderBtn {
                    margin-top: 25px;
                }
                .linearBorderBtn:not(:last-child){ 
                    margin-right: 30px;
                }
            }
            &:hover {
                .product-itsm-section1-img-wrapper {
                    transform: scale3d(1.02, 1.02, 1);
                }
            }
        }
        .product-itsm-section3-f {
            background: #fff;
            padding-bottom: 45px;
            .carousel-item img {
                width: 80%;
                height: 600px;
                margin: 0 auto 10px auto;
            }
        }
    }
    .tab-pane-wrapper {
        transition: all 0.5s ease-in-out;
        // display: none;
        opacity: 0;
        &.active {
            // display: block;
            opacity: 1;
        }
    }
}