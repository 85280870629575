.imgCard {
    overflow: hidden;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
    }
    .cardWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: transform 0.5s;
    }
    &:hover {
        .cardWrapper {
            transform: translate3d(0, -30px, 0);
        }
        img {
            transform: scale3d(1.1, 1.1, 1) translate3d(0, -20px, 0);
        }
    }
    .content-wrapper {
        flex: 1;
        padding: 30px 36px 33px 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .title {
        font-size: 30px;
        font-weight: $basic-font-weight-light;
        line-height: 42px;
        letter-spacing: 2px;
        color: #000;
        .tip {
            font-size: 14px;
            line-height: 32px;
            margin-left: 5px;
            @include linearText();
        }
    }
    .content {
        font-size: 16px;
        font-weight: $basic-font-weight-light;
        line-height: 32px;
        letter-spacing: 2px;
        color: #000;
        margin: 20px 0;
    }
    &.horizontal {
        .cardWrapper {
            flex-direction: row;
        }
        .img-wrapper {
            width: 50%;
        }
        .content-wrapper {
            padding: 0 58px;
            justify-content: center;
        }
        &:hover {
            .cardWrapper {
                transform: translate3d(0, -10px, 0);
            }
            img {
                transform: scale3d(1.05, 1.05, 1);
            }
        }
    }
}