.homeView {
    .home-section1-section2-wrapper {
        overflow: hidden;
    }
    .home-section2 {
        padding: 45px;
    }
    .home-section2-item {
        background: #fff;
        height: 100%;
    }
    .home-section3 {
        padding: 64px 24px;
        background: #fff;
    }
}
