.solutionsView {
    
}
.solutionsSubView {
    .solutions-sub-section2 {
        background: #fff;
        margin-top: 45px;
        &.normal-media-padding {
            padding-top: 15px !important;
            padding-bottom: 36px !important;
        }
        
    }
    .solutions-sub-title {
        padding-top: 10px;
        font-size: 25px;
        line-height: 36px;
        letter-spacing: 2px;
        color: #000;
        margin: 20px 0;
    }
    .solutions-sub-content {
        font-size: 16px;
        font-weight: $basic-font-weight-light;
        line-height: 30px;
        color: #0a0a0a;
        margin-bottom: 30px;
    }
    .solutions-sub-section3-block {
        height: 100%;
        background: #fff;
        padding: 25px 17px;
    }
    .solutions-sub-section3-block-title {
        font-size: 25px;
        font-weight: $basic-font-weight-light;
        line-height: 36px;
        letter-spacing: 2px;
        color: #000;
        margin-bottom: 15px;
    }
    .solutions-sub-section3-block-content {
        font-size: 16px;
        font-weight: $basic-font-weight-light;
        line-height: 32px;
        letter-spacing: 2px;
        color: #333;
    }
}
