.onlineExperienceView {
    .main-desc {
        @include linearText();
        display: inline-block;
        position: relative;
        font-weight: 500;
        cursor: pointer;
        &:after {
            content: '';
            height: 2px;
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            @include linearbackground();
        }
    }
}