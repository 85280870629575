.aboutView {
    .card-container {
        padding: 45px;
    }
    .card-item {
        background: #fff;
        height: 100%;
    }
    .about-section2 .imgCard .title {
        @include linearText();
    }
    .about-section3,
    .about-section4 {
        background: #fff;
        margin-top: 45px;
    }
    .about-section3-img-wrapper {
        text-align: center;
        img {
            height: 550px;
        }
        padding: 15px 0 45px 0;
    }
    .filter-list-wrapper {
        min-height: 500px;
    }
}
.newsSubView {
    .news-content {
        background: #fff;
        color: #0a0a0a;
        font-weight: $basic-font-weight-light;
        font-size: 16px;
        line-height: 30px;
    }
    .news-sub-intro,
    .news-sub-date {
        padding: 15px 0 25px 0;
    }
    .news-sub-title {
        font-size: 40px;
        line-height: 56px;
    }
    .pv-btn-wrapper {
        line-height: 56px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        div:not(:first-child) {
            text-align: right;
        }
        .btn-next,
        .btn-prev {
            font-size: 16px;
            padding: 0;
            .anticon {
                transition: transform 0.5s ease-in-out;
            }
            &:not([disabled]):hover {
                color: #1890ff;
            }
        }
        .btn-prev {
            &:not([disabled]):hover {
                .anticon {
                    transform: translate3d(-5px, 0, 0);
                }
            }
        }
        .btn-next {
            &:not([disabled]):hover {
                .anticon {
                    transform: translate3d(5px, 0, 0);
                }
            }
        }
        .pn-desc {
            font-size: 14px;
            line-height: 22px;
            color: #666;
            margin-bottom: 24px;
        }
    }
}