@import './variables.scss';
@import './mixins.scss';
@import './globalHeader.scss';
@import './globalFooter.scss';
@import './contactUs.scss';
@import './mainSectionDisplay.scss';
@import './wrapperButton.scss';
@import './imgCard.scss';
@import './blockCard.scss';
@import './home.scss';
@import './product.scss';
@import './customerCase.scss';
@import './about.scss';
@import './quickStart.scss';
@import './onlineExperience.scss';
@import './solutions.scss';


@font-face {
    font-family: PingFang;
    src: url('./fonts/PingFang SC Thin.otf');
}

body {
    font-family: PingFang, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 1px;
    font-size: 14px;
}
html,body,ul,li { margin: 0; padding: 0; }

.app-container {
    color: $basic-font-color;
    background: $basic-background;
    a {
        color: $basic-font-color;
    }
    .normal-media-width {
        width: 80%;
        margin: 0 auto;
    }
    .normal-media-padding {
        padding: 0 10%;
    }
    .normal-section-title {
        font-size: 30px;
        line-height: 42px;
        letter-spacing: 2px;
        color: #000;
        font-weight: $basic-font-weight-regular;
        text-align: center;
    }
    .normal-section-desc {
        font-size: 18px;
        line-height: 25px;
        font-weight: $basic-font-weight-light;
        color: #000;
        text-align: center;
        width: 80%;
        margin: 24px auto;
    }
    .section-title {
        font-size: 40px;
        font-weight: $basic-font-weight-light;
        line-height: 70px;
    }
    .section-desc {
        font-size: 25px;
        line-height: 60px;
        margin: 51px auto 34px;
        width: 70%;
        font-weight: $basic-font-weight-light;
    }
    .light-content {
        background-color: rgb(247, 250, 253);
    }
    .filter-bar {
        height: 89px;
        padding: 0 89px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
            display: inline-block;
            font-size: 20px;
            color: #4b4b4b;
            letter-spacing: 2px;
            width: 200px;
            text-align: center;
        }
        .ant-btn {
            height: 52px;
            margin-left: 28px;
            font-size: 20px;
            letter-spacing: 2px;
            border-color: #fff;
            padding: 0 20px 0 38px;
            border-radius: 8px;
            color: #4b4b4b;
            .anticon {
                margin-left: 20px;
            }
        }
    }
    .filter-list-wrapper {
        .filter-result {
            font-size: 15px;
            letter-spacing: 2px;
            color:  #4b4b4b;
            padding: 20px 142px 10px 142px;
        }
        .filter-list {
            padding: 0 80px;
        }
    }
    .verticalTab-wrapper {
        background: #fff;
        .ant-tabs {
            color: #0a0a0a;
            font-weight: $basic-font-weight-regular;
            // height: calc(100vh - 150px);
            .ant-tabs-left-bar {
                border-right: 1px solid #d1d1d1;
                margin-right: -1px;
                .ant-tabs-tab {
                    text-align: left;
                    padding: 4px 64px;
                    font-size: 16px;
                }
                .ant-tabs-ink-bar {
                    background-color: transparent;
                }
                .ant-tabs-nav {
                    margin: 10px 0;
                }
            }
            .ant-tabs-left-content {
                padding-left: 0px;
                border-left: 1px solid #d1d1d1;
            }
        }
        .ant-tabs-nav .ant-tabs-tab-active {
            .tab-title {
                @include linearText();
            }
            font-weight: 500;
            &:before {
                content: '';
                @include linearbackground();
                width: 30px;
                height: 8px;
                position: absolute;
                left: 0;
                top: calc(50% - 4px);
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
        .ant-tabs-tab-prev {
            color: #1890ff;
            &:hover {
                color: #0358a8;
            }
        }
        .content-title {
            font-size: 30px;
            line-height: 65px;
            padding-left: 43px;
            border-bottom: 1px solid #d1d1d1;
            font-weight: $basic-font-weight-medium;
            span {
                @include linearText();
            }
        }
        .content-container {
            padding: 24px 43px;
            // max-height: calc(100vh - 218px);
            overflow: auto;
            a {
                color: #1890ff;
                line-height: 32px;
            }
        }
    }
    .faqView {
        .faq-search {
            font-weight: $basic-font-weight-light;
            color: #999999;
            height: 60px;
            .ant-input {
                font-size: 18px;
                padding: 0 30px;
            }
            .anticon {
                margin-right: 10px;
            }
        }
        .bottom-extra {
            text-align: center;
            margin-top: 45px;
            font-size: 18px;
            font-weight: $basic-font-weight-light;
            letter-spacing: 2px;
            color: #000;
        }
    }
    .linearText {
        @include linearText;
    }
    .downloadView {
        .download-section1 {
            background: #fff;
        }
        .download-form-img {
            background-position: center;
            background-size: cover;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
        .download-img-mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
        }
        .download-form-container {
            color: #fff;
            max-width: 80%;
            z-index: 1;
            .anticon {
                font-size: 29px;
                line-height: 90px;
            }
        }
        .download-img-title {
            font-size: 25px;
            font-weight: $basic-font-weight-medium;
            line-height: 60px;
        }
        .download-img-desc-item {
            font-size: 16px;
            line-height: 30px;
        }
        .download-img-link {
            text-decoration: underline;
            font-size: 16px;
            line-height: 30px;
            cursor: pointer;
            margin-top: 45px;
        }
        .download-form-title {
            font-size: 25px;
            line-height: 60px;
            color: #0a0a0a;
            padding: 0 10%;
            margin-bottom: 25px;
            text-align: center;
        }
        .form-wrapper {
            height: 100%;
            padding: 45px 25px 21px 4px;
        }
        .download-section2 {
            background: #fff;
            margin-top: 45px;
            margin-bottom: 45px;
            padding-bottom: 45px;
        }
        .ant-table {
            font-size: 16px;
            color: #4a4a4a;
            font-weight: $basic-font-weight-light;
        }
        .ant-table-thead > tr > th {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #1890ff;
            background: transparent;
            font-size: 18px;
            font-weight: $basic-font-weight-regular;
        }
    }
    .news-other-content {
        margin-top: 45px;
    }
    .markdown-body {
        a {
            color: #1890ff;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            transition: color .3s;
            font-weight: 900;
            &:hover {
                color: #50a9fd;
            }
        }
        img {
            margin: auto;
            display: block;
            max-width: 95%;
        }
        table {
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            margin-bottom: 1.05rem;
            font-size: 14px;
            line-height: 25px;
            border-collapse: collapse;
            width: 100%;
            thead {
                text-align: left;
            }
            td:first-child, th:first-child {
                padding-left: .93333rem;
            }
            td, th {
                color: rgba(0,0,0,.85);
                text-align: left !important;
                border-bottom: 1px solid hsla(0,0%,0%,0.12);
                font-feature-settings: "tnum";
                padding-left: 0.93333rem;
                padding-right: 0.93333rem;
                padding-top: 0.7rem;
                padding-bottom: calc(0.7rem - 1px);
            }
            th {
                font-weight: bold;
            }
            td a {
                color: #1890ff;
                text-decoration: none;
                background-color: transparent;
                outline: none;
                cursor: pointer;
                transition: color .3s;
            }
        }
        h1, h2, h3, h4, h5, h6 {
            font-weight: 700;
            margin-bottom: 15px;
            color: rgba(0, 0, 0, 0.8);
            font-size: 1.38316rem;
        }
    }
    .hidden-expand-row {
        .ant-table-row-expand-icon-cell {
            visibility: hidden;
        }
    }
    .ant-table {
        font-size: 16px;
    }
    .table-text-bold,
    .ant-table-thead > tr > th:not(.table-text-config) {
        font-weight: 900;
        color: rgba(0, 0, 0, 0.85);
    }
    .table-text-config {
        background: #1890ff;
        color: #fff;
    }
    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, 
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, 
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, 
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        color: rgba(0, 0, 0, 0.85);
    }
}

.carousel-wrapper {
    .ant-carousel .slick-slide {
        overflow: hidden;
    }
    .ant-carousel .slick-dots li {
        margin: 0 10px;
        button {
            background: rgb(141, 137, 137);
            width: 36px;
            height: 4px;
        }
        &.slick-active button {
            @include linearbackground();
        }
    }
    .carousel-item {
        padding: 24px 0;
    }
}

.breadcrumb-wrapper {
    padding: 0 3%;
}

.custom-tab-center {
    .ant-tabs-bar {
        position: relative;
        border: none;
        &:after {
            content: ' ';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background: #c1c1c1;
        }
    }
    &.tab-left {
        font-weight: $basic-font-weight-medium;
        color: #0a0a0a;
        .ant-tabs-bar {
            margin: 0;
        }
        padding-bottom: 16px;
    }
    .ant-tabs-nav-wrap {
        margin-bottom: 0;
    }
    .ant-tabs-nav {
        font-size: 16px;
        line-height: 40px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        @include linearText();
        font-weight: normal;
    }
    .ant-tabs-ink-bar {
        @include linearbackground();
    }
}

.normal-form {
    input.ant-input {
        height: 44px;
    }
    .ant-form-item-label {
        line-height: 44px;
        padding-right: 30px;
        label {
            font-size: 18px;
            font-weight: $basic-font-weight-light;
        }
    }
    .ant-select-selection--single {
        height: 44px;
        > div {
            line-height: 44px;
        }
    }
    .ant-btn {
        @include linearbackground();
        height: 60px;
        padding: 0 35px;
        border: none;
        font-size: 18px;
        font-weight: $basic-font-weight-medium;
    }
}

