.wrapper-button {
    height: 54px;
    font-size: 18px;
    font-weight: $basic-font-weight-regular;
    &.hover-move-top {
        &:hover {
            transform: translate3d(0, -15px, 0);
        }
    }
    &.ant-btn.ant-btn-background-ghost.ant-btn:focus {
        color: #fff;
        border-color: #fff;
    }
    &.ant-btn.ant-btn-background-ghost:hover {
        background: #fff !important;
        color: #3023AE !important;
        border-color: #fff;
    }
    &.whiteBtn.ant-btn {
        span {
            @include linearText();
        }
        &:focus,
        &:hover {
            border-color: #fff;
        }
    }
    &.linearBtn {
        @include linearbackground();
        color: #fff;
        &:hover {
            border-color: #fff;
            background: #fff;
            color: #3023AE;
        }
    }
}
.linearBorderBtn {
    display: inline-block;
    @include linearbackground();
    padding: 1px;
    border-radius: 4px;
    .wrapper-button.ant-btn {
        border-radius: 3px;
        border: none;
    }
    &:hover {
        .wrapper-button.ant-btn {
            background: transparent;
            color: #fff;
        }
    }
}