.app-footer {
    background: #fff;
    padding: 34px 7% 18px 7%;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 50px;
    .logo {
        margin-bottom: 10px;
        background-size: cover;
        height: 40px;
        width: 145px;
    }
    .copyright {
        font-size: 12px;
        font-weight: 300;
        line-height: 30px;
        text-align: center;
        margin-top: 23px;
    }
    .left-item {
        margin-top: 15px;
    }
    .right-content {
        display: flex;
        justify-content: center;
    }
    @media only screen and (max-width: 1000px) {
        .right-content {
            justify-content: flex-start;
            margin-top: 20px;
        }
    }
    .right-item:not(:first-child) {
        margin-left: 56px;
    }
    .right-item-title {
        font-size: 20px;
        font-weight: $basic-font-weight-regular;
        line-height: 28px;
        color: #000;
        margin: 5px 0;
    }
    .right-subitem {
        display: inline-block;
        &:not(:first-child) {
            margin-left: 56px;
        }
    }
    .right-item-item {
        line-height: 30px;
        font-weight: $basic-font-weight-light;
        margin-top: 10px;
    }
}