.mainSection {
    position: relative;
    img {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .img-wrapper {
        height: 500px;
        overflow: hidden;
        position: relative;
    }
    .mainSectionContent {
        text-align: center;
        display: flex;
        align-items: center;
        & > div {
            width: 100%;
        }
        &.hasImg {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            .section-title {
                text-shadow: 5px 5px 5px rgb(2, 109, 197);
            }
            .section-desc {
                text-shadow: 5px 5px 5px rgb(2, 109, 197);
            }
        }
    }
}